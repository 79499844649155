.wrapper {
  & :global(.tabs-wrapper) {
    & :global(.badge-wrapper) {
      @apply inline-block;
      & span {
        @apply whitespace-nowrap inline-block py-1 px-2 ml-3 rounded-full bg-summer-green-100 font-sans text-sm;
      }
    }

    &:global(.style-hero) {
      & ul {
        @apply flex justify-center;
      }

      & :global(.tab) {
        @apply text-base md:text-lg font-bold font-serif border-t-4 border-transparent;
        @apply flex flex-nowrap justify-between items-center md:justify-center;
        @apply cursor-pointer;

        @apply w-1/2 max-w-[320px] px-4 pt-3 pb-5;

        &:global(.is-active) {
          @apply bg-summer-green-23 border-summer-green-100;
        }
      }
    }

    &:global(.style-welcome) {
      @apply bg-summer-green-23 pt-8 text-center;

      & ul {
        @apply w-[calc(100%-2rem)] md:w-auto rounded-md;
        @apply p-1 md:p-2 inline-flex bg-summer-green-50 md:rounded-lg text-left;
      }

      & :global(.tab) {
        @apply text-base px-4 py-4 lg:px-12 rounded-[4px] md:rounded-md font-serif font-bold leading-6;
        @apply flex flex-nowrap justify-between md:items-center;
        @apply cursor-pointer w-1/2 md:w-auto relative;

        & a {
          @apply inline-block leading-[21px];
        }

        &:global(.is-active) {
          @apply bg-white;
        }

        & :global(.badge-wrapper) {
          @apply absolute -top-4 right-2 md:relative md:top-0 md:right-0;
        }
      }
    }
  }

  & :global(.list-wrapper) {
    @apply bg-summer-green-23;
  }

  & :global(.categories-wrapper) {
    @apply max-w-8xl box-content px-4 mx-auto md:px-8 pt-6 md:pt-8;

    & ul {
      @apply inline-block mr-2;
    }

    & :global(.selected) {
      @apply inline-block py-1.5 px-2 mr-2 mb-2 bg-summer-green-50 rounded font-bold uppercase text-xs tracking-[1px];
    }

    & :global(.toggle-modal) {
      @apply font-bold font-sans text-base underline cursor-pointer;

      & svg {
        @apply inline-block w-4 h-4 ml-2;
      }
    }
  }

  & :global(.article-grid) {
    @apply max-w-8xl box-content px-4 py-4 mx-auto md:px-8 md:py-8 lg:pb-12 overflow-hidden;
    @apply grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 md:gap-6 lg:gap-x-13 lg:gap-y-10;
  }

  & :global(.no-content) {
    @apply col-span-4 text-center py-12;
  }

  & :global(.article-card) {
    @apply bg-white py-5 px-6 rounded-lg w-full h-full relative;

    & :global(.is-new) {
      @apply w-4 h-4 absolute -top-2 -right-2 bg-summer-green-100 rounded-full;
    }

    & :global(.tag) {
      @apply block font-sans font-bold uppercase text-xs tracking-[1px] mb-2;
    }

    & :global(.dateline) {
      @apply block mt-1.5 mb-1 font-sans text-sm text-black-100/80;
    }

    & :global(.terms) {
      @apply pt-3;
    }

    & :global(.term) {
      @apply inline-block py-1.5 px-2 mr-2 mb-2 bg-summer-green-23 rounded font-bold uppercase text-xs tracking-[1px];
    }

    & h3 {
      @apply font-sans text-xl font-medium max-w-full overflow-ellipsis inline;
      hyphens: auto;

      /* Texteffect */
      background-image: linear-gradient(currentColor, currentColor);
      background-position: 0% 100%;
      background-repeat: no-repeat;
      background-size: 0% 1px;
      transition: background-size 0.3s;
    }

    &:hover h3 {
      background-size: 100% 1px;
    }
  }

  & :global(.nav-wrapper) {
    @apply text-center pb-12 md:pb-20 px-4;
  }
}

.tabSelect {
  @apply h-15 w-[calc(100%-2rem)] mx-auto rounded;
  @apply relative;

  &:global(.is-open) {
    @apply z-40;
  }

  & :global(.radios) {
    @apply absolute top-0 left-0 w-full bg-white border border-black-25 rounded;

    &:global(.has-shadow) {
      @apply shadow-md;
    }
  }

  & header {
    @apply flex flex-row items-center justify-between px-4 py-3 cursor-pointer;

    & :global(.current-choice) {
      @apply text-lg leading-none pointer-events-none text-left flex-grow;

      & > span {
        @apply block pb-1 font-sans text-xs font-bold leading-none tracking-widest;
      }

      & label {
        @apply flex items-center justify-between;
      }
    }

    & :global(.badge-wrapper) {
      & span {
        @apply font-bold mr-4;
      }
    }

    & svg {
      @apply w-[11px] h-auto pointer-events-none;
    }
  }

  & :global(.open-filter) {
    @apply relative z-30 bg-white rounded-b overflow-hidden;

    & :global(.overlay) {
      @apply absolute w-full h-full z-40 pointer-events-none;
    }

    & :global(ul) {
      @apply overflow-y-scroll max-h-[416px] relative;

      &::-webkit-scrollbar {
        @apply w-1;
      }
      &::-webkit-scrollbar-track {
        @apply bg-black-25;
      }
      &::-webkit-scrollbar-thumb {
        @apply bg-black-100;
      }
      &::-webkit-scrollbar-thumb:hover {
        @apply bg-black-75;
      }
    }

    & :global(.button-wrapper) {
      @apply px-4 pb-3 mt-2;

      & :global(.button) {
        @apply w-full font-sans;
        &:global(.dim) {
          @apply opacity-20;
        }
      }
    }
  }

  & :global(.option) {
    @apply cursor-pointer h-10 px-4 flex items-center;
    @apply text-base select-none;

    &:global(.is-active) {
      @apply bg-summer-green-25;
    }

    & label {
      @apply cursor-pointer;
    }

    & input {
      @apply hidden;
    }
  }
}
