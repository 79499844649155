.article {
  @apply flex w-full;

  &:hover img {
    @apply image-hover-effect;
  }

  &:hover .headline {
    @apply text-hover-effect;
  }
}

.image {
  @apply absolute top-0 left-0 w-full h-full image-has-hover-effect;
}

.headline {
  @apply inline mb-2 font-sans font-medium text-has-hover-effect;
}

.figure {
  @apply relative w-full overflow-hidden pb-full;
}

.link {
  @apply w-1/3 h-full mr-5;
}

.meta {
  @apply block text-sm mt-2.5 lg:mt-3;
}
